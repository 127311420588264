import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/BlogTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "coqui-studio-the-studio-for-generative-ai-voice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-studio-the-studio-for-generative-ai-voice",
        "aria-label": "coqui studio the studio for generative ai voice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui Studio: The Studio for Generative AI Voice`}</h3>
    <p>{`Coqui Studio is an AI voice directing platform that allows users to generate, clone, and control AI voices
for video games, audio post-production, dubbing, and more. It features a large set of generative AI voices,
an advanced editor for tuning each voice, tools for managing projects & scripts, and tons of tools for
editing timelines, all to help users streamline their workflow. We’re now beta-launching “prompt-to-voice”,
an unprecedented way to generate unique voices.`}</p>
    <h3 {...{
      "id": "what-is-prompt-to-voice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-prompt-to-voice",
        "aria-label": "what is prompt to voice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`What is Prompt-to-Voice?`}</h3>
    <p>{`Prompt-to-voice is a feature that allows users to generate realistic and expressive AI voices from natural
language prompts. A prompt is a short text that describes what kind of voice or speech you want to generate.
For example:`}</p>
    <div style={{
      "width": "700px"
    }} align="center">
  <figcaption style={{
        "width": "400px"
      }}>
    <i>An older man with a British accent and a pleasing, deep voice.</i>
  </figcaption>
  <audio controls src="https://coqui-ai-public-data.s3.amazonaws.com/website/audio/old-british-man.wav" type="audio/wav"></audio>
  <br />
  <br />
  <figcaption style={{
        "width": "400px"
      }}>
    <i>
      A female senior with a guttural voice and an American accent. Their voice should be hoarse and
      have a boisterous intensity.
    </i>
  </figcaption>
  <audio controls src="https://coqui-ai-public-data.s3.amazonaws.com/website/audio/old-american-woman.wav" type="audio/wav"></audio>
  <br />
  <br />
  <figcaption style={{
        "width": "400px"
      }}>
    <i>
      A female young adult with a childlike voice and an American accent. Their voice should be
      polished and breathy and have a ringing but feathery intensity with a calming delivery.
    </i>
  </figcaption>
  <audio controls src="https://coqui-ai-public-data.s3.amazonaws.com/website/audio/young-american-female-adult.wav" type="audio/wav"></audio>
  <br />
  <br />
    </div>
    <p>{`By using prompts, users can easily specify the characteristics and style of desired voice. Coqui Studio
uses state-of-the-art generative AI technology to turn prompts into high-quality AI voices that are ready
for your project.`}</p>
    <h3 {...{
      "id": "how-does-prompt-to-voice-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-does-prompt-to-voice-work",
        "aria-label": "how does prompt to voice work permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`How Does Prompt-to-Voice Work?`}</h3>
    <p>{`Prompt-to-voice works by using generative AI models that are trained on large amounts of speech data. These
models learn how to map text inputs to a space of possible voices. Then they can use this mapping to generate
novel and diverse AI voices that match the input specifications.`}</p>
    <p>{`One way to understand how prompt-to-voice works is by making an analogy to image generation models like Dall-E
or Midjourney. These models can generate realistic and creative images from natural language prompts. Similarly,
prompt-to-voice can generate realistic and creative voices from natural language prompts.`}</p>
    <h3 {...{
      "id": "why-is-prompt-to-voice-useful",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#why-is-prompt-to-voice-useful",
        "aria-label": "why is prompt to voice useful permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Why is Prompt-to-Voice Useful?`}</h3>
    <p>{`Prompt-to-voice is useful because it enables users to create unique and customized AI voices for various purposes.
Some of the benefits of prompt-to-voice are:`}</p>
    <h4 {...{
      "id": "creativity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#creativity",
        "aria-label": "creativity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Creativity`}</h4>
    <p>{`Prompt-to-voice allows users to explore their creativity and imagination by generating voices that are not limited
by existing recordings or templates. Users can experiment with different combinations of attributes, styles,
characteristics, etc., and discover new possibilities for their projects.`}</p>
    <h3 {...{
      "id": "efficiency",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#efficiency",
        "aria-label": "efficiency permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Efficiency`}</h3>
    <p>{`Prompt-to-voice saves users time and effort by generating voices instantly from text inputs. Creators do not need
to spend hours casting the right voice for their content and avoid fees that are attached to it.`}</p>
    <h4 {...{
      "id": "flexibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#flexibility",
        "aria-label": "flexibility permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Flexibility`}</h4>
    <p>{`Prompt-to-voice gives users more control and flexibility over their voice outputs. Users can easily modify or update
their prompts according to their needs or preferences. They can also adjust parameters such as pitch, speed, volume,
etc., to fine-tune their results once they find the voice they desire.`}</p>
    <h3 {...{
      "id": "give-it-a-try",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#give-it-a-try",
        "aria-label": "give it a try permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Give It a Try`}</h3>
    <div align="center">
  <iframe scrolling="no" style={{
        "width": "700px",
        "height": "750px"
      }} src={`${process.env.GATSBY_BACKEND_URL}/widget/prompt-to-voice`}></iframe>
    </div>
    <p><a parentName="p" {...{
        "href": "https://app.coqui.ai/auth/signup"
      }}>{`Sign up`}</a>{` for Coqui Studio (you get 30 free minutes when you sign up) and start
using prompt-to-voice in your next project (If you have feedback, you can find us on `}<a parentName="p" {...{
        "href": "https://discord.gg/zvaJE2MHVf"
      }}>{`Discord`}</a>{`)`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      